.categories-section {
    .categories-wrapper {
        display: flex;
        flex-direction: column;
        // @media( max-width: 1250px ) {
        //     flex-direction: column;
        // }
        .category-boxes {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -15px;
            position: relative;
            .category {
                display: flex;
                flex-direction: column;
                max-width: calc(33.3% - 30px);
                width: 100%;
                margin: 0 15px 15px 15px;
                border: 2px solid $categoriesBorder;
                box-shadow: 1px 3px 13px 0px rgba(0, 0, 0, 0.35);
                -webkit-box-shadow: 1px 3px 13px 0px rgba(0, 0, 0, 0.35);
                -moz-box-shadow: 1px 3px 13px 0px rgba(0, 0, 0, 0.35);
                background: #fff;
                height: min-content;
                @media( max-width: 1300px ) {
                    max-width: calc(50% - 30px);
                }
                @media( max-width: 900px ) {
                    max-width: 100%;
                }
                &.check {
                    border: 2px solid $categoriesBorderSelect;
                }
                    .header {
                        padding: 10px;
                        border-bottom: 2px solid $categoriesBorder;
                        position: relative;
                        cursor: pointer;
                        transition: .3s;
                        background: #f0f0f0;
                        &:hover {
                            background: #e0dede;
                        }
                        span {
                            font-weight: bold;
                            color: $categoriesFontColor;
                            font-size: 1rem;
                        }
                    }
                    .content {
                        padding: 10px;
                        .row-item {
                            padding-bottom: 5px;
                            margin-bottom: 10px;
                            border-bottom: 1px solid $categoriesBorder;
                            .header-item {
                                display: flex;
                                justify-content: space-between;
                                .svg-icon {
                                    cursor: pointer;
                                    &:hover {
                                        svg {
                                            fill: $error;
                                        }
                                    }
                                }
                                .category-input {
                                    border: none;
                                    outline: none;
                                    color: $categoriesFontColor;
                                    font-size: 0.75rem;
                                    min-width: 80%;
                                }
                            }
                        }
                    }
            }
        }
        .create-new-item {
            max-width: 230px;
            width: 100%;
            margin-bottom: 30px;
            border: 2px solid $categoriesBorder;
            box-shadow: 1px 3px 13px 0px rgba(0, 0, 0, 0.35);
            -webkit-box-shadow: 1px 3px 13px 0px rgba(0, 0, 0, 0.35);
            -moz-box-shadow: 1px 3px 13px 0px rgba(0, 0, 0, 0.35);
            background: #ffffff;
            padding: 15px;
            input {
                margin-bottom: 15px;
                width: 100%;
                padding: 5px 10px;
                border: 2px solid $categoriesBorder;
                z-index: 999999;
                outline: none;
                color: $categoriesFontColor;
                &.error {
                    border: 2px solid $error;
                }
            }
            label {
                display: block;
                padding-bottom: 5px;
                color: $categoriesFontColor;
            }
        }
        .save {
            max-width: 230px;
            width: 100%;
        }
    }
}