.btn-wrap {
    border: 2px solid transparent;
    width: max-content;

    &:focus-visible {
        border-color: $btnFocusColor;
    }
    .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        padding: 13px 38px;
        font-size: 1.125rem;
        font-weight: bold;
        border-radius: 30px;
        cursor: pointer;
        transition: .3s;
        @media( max-width: 1000px ) {
            font-size: 1rem;
            padding: 15px 20px;
        }
        &.small {
            padding: 7px 20px;
            font-size: 1rem;
        }
        &.btn-primary {
            background-color: $btnPrimaryBg;
            color: $btnPrimaryFont;
            &:hover {
                background-color: $btnPrimaryBgHover;
            }
        }
        &.btn-secondary {
            background-color: $btnSecondaryBg;
            color: $btnSecondaryFont;
            padding: 13px 65px;
            @media( max-width: 1000px ) {
                padding: 15px 25px;
            }
            &:hover {
                background-color: $btnSecondaryBgHover;
                color: $btnSecondaryFontHover;
            }
        }
        &.block {
            background-color: $btnBlockBg;
            color: $btnBlockFont;
            cursor: default;
            &:hover {
                background-color: $btnBlockBg;
                color: $btnBlockFont;
            }
        }
        .left-icon {
            margin-right: 17px;
        }
        &.disable {
            background-color: $contrastDisable;
            color: $contrastPrimary;
        }
    }
}

.contrast-wcag {
    .btn-wrap {
        &:focus-visible {
            border-color: $contrastWhite;
        }
        .btn {
            &.btn-primary, &.btn-secondary {
                background-color: $contrastPrimary;
                color: $contrastSecondary;
                &:hover {
                    background-color: $contrastWhite;
                    color: $contrastSecondary;
                }
            }
            &.block {
                background-color: $contrastDisable;
                color: $contrastSecondary;
                &:hover {
                    background-color: $contrastDisable;
                    color: $contrastSecondary;
                }
            }
        }
    }
}