.new-user-container {
	.col-wrapper {
		display: flex;

		@media (max-width: 1060px) {
			flex-direction: column-reverse;
		}
	}
	.col {
		width: 50%;

		@media (max-width: 1060px) {
			width: 100%;
		}
		&:last-child {
			padding-left: 60px;

			@media (max-width: 1060px) {
				padding-left: 0;
			}
		}
	}

	.input-wrapper-component, .date-wrapper, .select-wrap {
		margin-bottom: 15px;
	}

	.date-wrapper {
		display: flex;
		flex-direction: column;
		label {
			color: $secondaryColor;
			font-weight: 700;
			margin-bottom: 10px;
			text-align: left;
			width: 100%;
			display: block;
		}
		.react-datetime-picker__inputGroup {
			min-height: 39px;
			padding: 9.5px 20px;
		}
	}

	.btn-wrapper {
		margin-top: 30px;
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}

	.info-permission {
		h2 {
			font-size: 1.5rem;
			color: $secondaryColor;
			font-weight: 400;
		}

		h3 {
			font-size: 1rem;
			color: $secondaryColor;
			font-weight: 700;
		}

		ul {
			margin-top: 30px;
			padding-left: 0;

			li {
				font-size: 1rem;
				color: $PermissionsFontColor;
				list-style: none;
				margin-bottom: 10px;
			}
		}
	}

	.avatar-wrap {
		display: flex;
		flex-wrap: wrap;

		.avatar-title {
			color: $secondaryColor;
			width: 100%;
			font-size: 1rem;
			font-weight: 700;
			margin-bottom: 20px;
		}

		.upload-column {
			max-width: 200px;
			width: 100%;
			margin-right: 50px;
		}

		.upload-btn-wrapper {
			display: flex;
			flex-direction: column;
		}
		.btn-wrap {
			width: 100%;
			margin-bottom: 10px;
		}
		.btn-wrap .btn.btn-secondary {
			padding: 13px 16px;
			max-width: 100%;
			width: 100%;
		}
		.avatar {
			max-width: 160px;
			width: 100%;
			height: 'auto';
		}
		.upload-file {
			margin-right: 20px;
		}
	}

	hr {
		height: 1px;
		width: 100%;
		border: none;
		background-color: $secondaryColor;
		color: $secondaryColor;
		display: block;
		margin: 30px 0;
	}
}

.contrast-wcag {
	.info-permission {
		h2 {
			color: $contrastPrimary;
		}

		h3 {
			color: $contrastPrimary;
		}

		ul {
			li {
				color: $contrastWhite;
			}
		}
	}

	.avatar-wrap {
		.avatar-title {
			color: $contrastPrimary;
		}
	}

	.date-wrapper {
		label {
			color: $contrastPrimary;
		}
	}
	hr {
		background-color: $contrastWhite;
		color: $contrastWhite;
	}
}
