.upload-area {
	padding: 40px 100px;
	border: 1px dashed $secondaryColor;

	@media (max-width: 500px) {
		padding: 40px 20px;
	}
	.drag {
		color: $secondaryColor;
		margin-bottom: 10px;
		font-size: 1rem;
		line-height: 1.4rem;
	}
}

.password {
	.input-box {
		#password {
			padding: 9.5px 58px 9.5px 20px;
		}
	}
}

.input-wrapper-component {
	position: relative;
	.input-box {
		position: relative;
		border: 1px solid transparent;
		&:focus-within,
		&:focus-visible {
			border-color: $btnFocusColor;
		}
	}
	&.password {
		.input-box {
			input {
				padding-right: 50px;
			}
		}
	}
	input,
	textarea,
	.textarea,
	button {
		position: relative;
		background-color: transparent;
		max-width: 100%;
		width: 100%;
		text-shadow: none;
		border: none;
		padding: 12px 20px;
		outline: none;
		font-size: 18px;
		font-weight: 200;
		border-radius: 30px;
		font-weight: bold;
		font-family: $fontPrimary;
	}
	textarea,
	.textarea {
		padding-top: 20px;
		height: 100%;
		resize: none;
		outline: none;
		min-height: 150px;
		max-height: 150px;
		overflow: auto;
	}

	.clear {
		position: absolute;
		top: 2px;
		right: 20px;
		cursor: pointer;
		z-index: 10;
	}

	button {
		cursor: pointer;
	}

	button {
		cursor: pointer;
	}

	label, .clear {
		display: block;
		margin-bottom: 8px;
		font-weight: bold;
	}

	.magnify {
		position: absolute;
		top: calc(50% - 12px);
		right: 20px;
		cursor: pointer;
		svg {
			width: 24px;
			height: 24px;
		}
	}

	.password-icon {
		display: flex;
		position: absolute;
		top: calc(50% - 12px);
		right: 20px;
		cursor: pointer;
		svg {
			width: 24px;
			height: 24px;
		}
	}

	input[type=number] {
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}
	}

	input[type=search] {
		&::-webkit-search-cancel-button {
			-webkit-appearance: none;
		}
		padding-left: 30px;
		padding-right: 40px;
	}

	.upload-result-text {
		margin-top: 10px;
	}

	&.btn-primary {
		input,
		textarea,
		.textarea,
		button {
			border: 1px solid $btnPrimaryBorder;
			color: $btnSecondaryFont;
			&::placeholder {
				color: $btnSecondaryFont;
			}
		}
		label,
		.upload-result-text, .clear {
			color: $btnSecondaryFont;
		}
		.magnify {
			svg {
				fill: $btnSecondaryFont;
			}
		}
	}
	&.btn-tertiary {
		input,
		textarea,
		.textarea,
		button {
			border: 1px solid $btnTertiaryBorder;
			color: $btnTertiaryFont;
			&::placeholder {
				color: $btnTertiaryFont;
			}
		}
		label,
		.upload-result-text {
			color: $btnTertiaryFont;
		}
		.magnify {
			svg {
				fill: $btnTertiaryFont;
			}
		}
	}

	&.btn-simple {
		.input-box {
			// &:focus-within {
			//     border: 2px solid transparent;
			// }
		}
		input {
			padding: 9.5px 20px;
			font-weight: 400;
			font-size: 1rem;
			color: $btnBlockFont;

			// &:focus-within {
			//     border: 1px solid $contrastSecondary;
			// }
		}
		input,
		textarea,
		.textarea,
		button {
			border-radius: 0;
			border: 1px solid $secondaryColor;

			&::placeholder {
				color: $btnTertiaryFont;
			}
		}
	}

	&.disabled {
		input {
			background-color: #ececec;
		}
	}
}

.contrast-wcag {
	.upload-area {
		border: 1px dashed $contrastSecondary;

		.upload-area {
			border: 1px dashed $contrastSecondary;
			.drag {
				color: $contrastSecondary;
			}
		}
		.input-wrapper-component {
			&.btn-primary,
			&.btn-tertiary {
				input,
				textarea,
				.textarea,
				button {
					border: 1px solid $contrastWhite;
					color: $contrastWhite;
					&::placeholder {
						color: $contrastWhite;
					}
					&:focus-visible {
						border-color: $contrastPrimary;
					}
				}
				label,
				.upload-result-text {
					color: $contrastWhite;
				}
				.magnify {
					svg {
						fill: $contrastWhite;
					}
				}
			}
		}
	}

	.btn-simple, .btn-primary {
		input,
		textarea,
		.textarea,
		button {
			border: 1px solid $contrastWhite;
			color : $contrastWhite;
			
			&::placeholder {
				color: $contrastWhite;
			}
			&:focus-visible {
				border-color: $contrastPrimary;
			}
		}

		.magnify {
			svg {
				fill: $contrastWhite;
			}
		}
		
		label, .clear {
			color: $contrastPrimary;
		}
	}

	.disabled {
		input {
			background-color: #2a2a2a;
		}
	}
	
	
}
